// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 320px;
	max-height: 640px;

	@include media-breakpoint-up(sm) {
		height: 75vh;
	}

	@include media-breakpoint-up(lg) {
		min-height: 640px;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 350px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		min-height: 400px;
		height: 90vh;
	}

	@include media-breakpoint-up(lg) {
		min-height: 800px;
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	background-color: $green-light;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
			}
		}
	}

	// owl-dots
	.owl-nav,
	.owl-dots {
		display: none;
	}
}
